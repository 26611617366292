@import "~app/mixins";

$border-size: 1px;

.root {
  height: 64px;
  width: 116px;
  border-radius: 4px;
  border-width: 0;
  background: $color-white;
  color: $color-grey;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  @include primary-color-box-shadow('1px');

  &.active,
  &:active {
    border: $border-size solid transparent;
    color: $color-white;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    @include primary-background-color();
    @include primary-border-color();

    svg use {
      fill: $color-white;
    }

    &:hover {
      @include primary-background-color-dark();

      svg use {
        fill: $color-white;
      }
    }
  }

  &:focus {
    @include primary-color-box-shadow('1px', '4px');
  }

  &:hover {
    background: $color-light-blue;

    @include primary-color-box-shadow('1px');
  }

  > * {
    max-width: 100%;
  }

  > div {
    width: 100%;
  }

  &[disabled] {
    box-shadow: 0 0 0 1px $color-grey;
    border-color: $color-grey;
    color: $color-grey;
    cursor: not-allowed;

    &.active,
    &:active {
      border-color: $color-grey;
      color: $color-grey;
      background: transparent;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      svg use {
        fill: $color-grey;
      }

      &:hover {
        background: transparent;

        svg use {
          fill: $color-grey;
        }
      }
    }

    &:focus {
      box-shadow: 0 0 4px 1px $color-grey;
    }

    &:hover {
      background: transparent;
      box-shadow: 0 0 0 1px $color-grey;
    }

    > * {
      max-width: 100%;
    }

    > div {
      width: 100%;
    }
  }

  &.small {
    .value {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.value {
  font-size: 16px;
  line-height: 28px;
}

.label {
  font-size: 12px;
  line-height: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: italic;
}
